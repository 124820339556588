<template>
  <div>
    <PlaceHolder
        :src="require('@/assets/img/svg/no-notifications.svg')"
        title="No bids placed at the moment."
        text=""
        v-if="bids.length === 0"
      />
    <div v-for="(bid, index) in bids" :key="index">
      <v-card height="200px" :color="ant">
        <v-card-text>
          <bidComponent :bid="bid" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import validationMxn from '@/mixins/validation_mixin';
import timezoneMxn from '@/mixins/timezone_mixin';
import connection from '@/socket/utils';

export default {
  name: "bidList",
  components: {
    PlaceHolder: () => import("@/components/core/PlaceHolder"),
    bidComponent: () => import("@/modules/MyOrders/MyBids/bidComponent"),
  },
  mixins: [colorMxn, validationMxn, timezoneMxn],
  data() {
    return {
      bids: [],
      chatSocket: null,
    };
  },
  mounted() {
    this.getBids();
    connection.connect();
    this.chatSocket = connection.subscribe(`chat:bids`, this.handleMessageAdd);
  },
  methods: {
    async getBids() {
      const payload = {
        page: 1,
        order_id: this.$route.params.id,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/bid/order-bids'
      };

      const response = await this.performGetActions(fullPayload);
      this.bids = response.data;
    },
    handleMessageAdd(data) {
      this.bids.push(data[0]);
    },
  }
};
</script>
